/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Cuando hice mis últimos ", React.createElement(_components.a, {
    href: "/2023/02/05/cambios-en-el-blog/"
  }, "cambios en el blog"), " me replanteé si necesitaba muchas cosas como por ejemplo estas:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Analíticas: Muy poca gente entra en mi blog, ¿para qué necesito recordármelo?"), "\n", React.createElement(_components.li, null, "Comentarios: Si no tengo una comunidad detrás los comentarios son una característica inútil."), "\n"), "\n", React.createElement(_components.p, null, "A eso se unía que si eliminaba analíticas podía quitar el fastidioso banner de las cookies. Así que después de meditarlo un poco decidí abrazar el minimalismo y eliminar todo los superfluo. Tengo que decir que también perdí otras cosas por el camino sin quererlo, como el botón de RSS, la paginación o los tags, pero estoy trabajando para devolverlo cuanto antes."), "\n", React.createElement(_components.p, null, "Somos ya bastantes personas que por rebelión o simplemente hartazgo por la web actual hemos recorrido este camino, todo esto ha terminado cristalizando en un pequeño movimiento, encabezado por ", React.createElement(_components.a, {
    href: "https://maestrapaladin.es/articulos/2024/01/websencilla"
  }, "Maestra Paladín"), ", llamado WebSencilla.\nLa WebSencilla pretende devolver a la web su uso original, la conexión entre personas y revelarse contra la mercantilización de la red de redes. Este movimiento ha venido creciendo en el caldo de cultivo del fediverso y los propios blogs, para más información te recomiendo este post de ", React.createElement(_components.a, {
    href: "https://adrianperales.com/2024/01/websencilla/"
  }, "Adrián Perales"), "."), "\n", React.createElement(_components.p, null, "Además puedes seguir en las redes federadas la etiqueta #WebSencilla donde se comparten opiniones y recursos para crear tu web. También hay un ", React.createElement(_components.a, {
    href: "https://webring.tr4ck.net/"
  }, "webring"), " con los blogs que se adhieren."), "\n", React.createElement(_components.p, null, "Realmente me hace ilusión este nuevo movimiento, sobre todo porque está convenciendo a gente para crear su propio blog. Esto siempre es una buena noticia porque mantener un blog es la única manera de construir tu propio relato, en otras palabras, de crear tu propia agenda."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
